












































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ILabeledItem, ILabeledItemGroup } from '@/types/ILabeledItem';
import LabeledItemList from '@/components/global/LabeledItem/LabeledItemList.vue';

@Component({
    name: 'ChangeMediaModal',
    components: {
        LabeledItemList
    }
})
export default class ChangeMediaModal extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Array })
    mediaItems: any[];

    /* Computed
    ============================================*/
    get labeledItemGroup(): ILabeledItemGroup[] {
		if (!this.mediaItems.length) return [];
		let mediaItems: ILabeledItemGroup[] = [{
			title: '0 - empty',
			items: [
				{ label: 'Resource', value: '*' },
				{ label: 'Media Label', value: 'empty' }
			]
		}];

		this.mediaItems.forEach((item, index) => {
			mediaItems.push({
				title: `${index + 1} - ${item.type}`,
				items: [{
					label: 'Resource',
					value: item.dataResourceType
				},
				{
					label: 'Media Label',
					value: item.id + (item.label ? ` (${item.label})` : '')
				}]
			});
		});

		return mediaItems;
    }

    /* Data
    ============================================*/
	selectedMediaId: string = null;
	select(mediaId: string) {
		this.selectedMediaId = mediaId;
	}

}
