












































































































import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import {IEaasiResource, IEnvironment, ResourceType} from '@/types/Resource';
import Draggable from 'vuedraggable';
import {archiveTypes, resourceTypes, translatedIcon} from '@/utils/constants';
import {getResourceTypeTags} from '@/helpers/ResourceHelper';
import EmulationProjectEnvironment from '@/models/emulation-project/EmulationProjectEnvironment';
import EmulationProjectEnvironmentMetadata
	from '@/components/emulation-project/metadata/EmulationProjectEnvironmentMetadata.vue';
import _ from 'lodash';
import {Get, Sync} from 'vuex-pathify';

@Component({
	name: 'EmulationProjectBasicModeScreen',
	components: {
		EmulationProjectEnvironmentMetadata,
		Draggable
	}
})
export default class EmulationProjectBasicModeScreen extends Vue {

	selectedEnvironment: IEnvironment[] = [];

	@Sync('emulationProject/selectingResourceTypes')
	selectingResourceTypes: ResourceType[];

	@Sync('emulationProject/environment')
	environment: EmulationProjectEnvironment;

	@Sync('emulationProject/selectedResources')
	selected: IEaasiResource[];

	@Get('emulationProject/projectEnvironments')
	readonly environments: IEnvironment[];

	startSelectingEnvironment() {
		this.selectingResourceTypes = _.uniq([...this.selectingResourceTypes, resourceTypes.ENVIRONMENT]);
	}

	resetSelectingEnvironmentType() {
		this.selectingResourceTypes = _.filter(this.selectingResourceTypes, type => type !== resourceTypes.ENVIRONMENT);
		this.selectedEnvironment = [];
	}

	get isSelectingEnvironment() {
		return this.selectingResourceTypes.includes(resourceTypes.ENVIRONMENT);
	}

	get isEnvironmentSelected() {
		return this.selectedEnvironment.length !== 0;
	}

	startSelectingObject() {
		this.selectingResourceTypes = _.uniq([...this.selectingResourceTypes, resourceTypes.CONTENT, resourceTypes.SOFTWARE]);
	}

	resetSelectingObjectType() {
		this.selectingResourceTypes = _.filter(this.selectingResourceTypes, type => type !== resourceTypes.CONTENT && type !== resourceTypes.SOFTWARE);
		this.selected = [];
	}

	get isSelectingObject() {
		return this.selectingResourceTypes.includes(resourceTypes.CONTENT) || this.selectingResourceTypes.includes(resourceTypes.SOFTWARE);
	}

	get isObjectSelected() {
		return this.selected.length !== 0;
	}

	getTypeTags(resource: IEaasiResource) {
		return getResourceTypeTags(resource);
	}

	getArchiveTags(resource: IEaasiResource) {
		const archive = resource.archive || resource.archiveId;
		let tagGroup = [];
		if (archive === archiveTypes.PUBLIC) {
			tagGroup.push({
				icon: translatedIcon('map-marker'),
				color: 'green',
				text: 'Saved Locally'
			});
		} else if (archive === archiveTypes.REMOTE) {
			tagGroup.push({
				icon: 'fa-cloud',
				color: 'white',
				text: 'Remote'
			});
		} else if (archive === archiveTypes.DEFAULT) {
			tagGroup.push({
				color: 'yellow',
				text: 'Local'
			});
		}
		return tagGroup;
	}

	updateEnvironmentList(evt) {
		if (evt.added) {
			this.environment = new EmulationProjectEnvironment(evt.added.element);
			this.selectingResourceTypes = _.filter(this.selectingResourceTypes, type => type !== resourceTypes.ENVIRONMENT);
		}
	}

	updateObjectsList(evt) {
		if (evt.added) {
			this.selected = [evt.added.element];
			this.selectingResourceTypes = _.filter(this.selectingResourceTypes, type => type !== resourceTypes.CONTENT && type !== resourceTypes.SOFTWARE);
		}
	}

	clearEnvironment() {
		this.environment = null;
		this.selectedEnvironment = [];
	}

	clearObject() {
		this.selected = [];
	}

	beforeMount() {
		if (this.environment) {
			const environment = this.environments.find(env => env.envId === this.environment.envId);
			if (environment) {
				this.selectedEnvironment = [environment];
			}
		}
	}

	@Watch('environment')
	setSelectedEnvironment(nextEnvironment: EmulationProjectEnvironment) {
		if (nextEnvironment) {
			const environment = this.environments.find(env => env.envId === nextEnvironment.envId);
			if (environment) {
				this.selectedEnvironment = [environment];
			}
		} else {
			this.selectedEnvironment = [];
		}
	}


}
