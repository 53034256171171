























































































import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {Get, Sync} from 'vuex-pathify';
import {ICreateEnvironmentPayload, ITemplate} from '@/types/Import';
import EmulationProjectHardwareMetadata
	from '@/components/emulation-project/metadata/EmulationProjectHardwareMetadata.vue';
import { IDriveSetting, IEaasiResource, ResourceType } from '@/types/Resource';
import Checkbox from '@/components/global/forms/Checkbox.vue';
import Draggable from 'vuedraggable';
import _ from 'lodash';
import {archiveTypes, AVAILABLE_RESOURCES_BY_DISK_TYPE, translatedIcon} from '@/utils/constants';
import {getResourceTypeTags} from '@/helpers/ResourceHelper';

@Component({
	name: 'EmulationProjectAdvancedModeScreen',
	components: {
		EmulationProjectHardwareMetadata,
		Checkbox,
		Draggable
	}
})
export default class EmulationProjectAdvancedModeScreen extends Vue {

	@Sync('emulationProject/selectedTemplateId')
	selectedTemplateId: string;

	selectingResourceForDiskIndex: number = -1;

	@Sync('emulationProject/selectedResourcesPerDrive')
	selectedResourcesPerDrive: IEaasiResource[][];

	@Sync('resource/availableTemplates')
	availableTemplates: ITemplate[];

	@Sync('emulationProject/createEnvironmentPayload')
	createEnvironmentPayload: ICreateEnvironmentPayload;

	@Sync('emulationProject/createEnvironmentPayload@driveSettings')
	driveSettings: IDriveSetting[];

	@Sync('emulationProject/selectingResourceTypes')
	selectingResourceTypes: ResourceType[];

	@Get('emulationProject/projectObjects')
	objects: IEaasiResource[];

	@Get('emulationProject/projectImages')
	images: IEaasiResource[];

	get selectedTemplate() {
		return this.availableTemplates.find(template => template.id === this.selectedTemplateId);
	}

	isDropzoneVisible(driveSetting: IDriveSetting) {
		return this.selectingResourceForDiskIndex === driveSetting.driveIndex || this.isResourceSelected(driveSetting);
	}

	isResourceSelected(driveSetting: IDriveSetting) {
		return !_.isEmpty(this.selectedResourcesPerDrive[driveSetting.driveIndex]);
	}

	isSelectionAvailable(driveSetting: IDriveSetting) {
		try {
			return [...this.objects, ...this.images].some(object => AVAILABLE_RESOURCES_BY_DISK_TYPE[driveSetting.drive.type].includes(object.resourceType));
		} catch (e) {
			return false;
		}
	}

	getDiskLabel(type: string) {
		switch (type) {
			case 'disk':
				return 'Hard Drive';
			case 'cdrom':
				return 'CD-ROM';
			case 'floppy':
				return 'Floppy Disk';
			default:
				return '';
		}
	}

	startSelectingResource(driveSetting: IDriveSetting) {
		this.selectingResourceForDiskIndex = driveSetting.driveIndex;
		this.selectingResourceTypes = _.uniq([...this.selectingResourceTypes, ...AVAILABLE_RESOURCES_BY_DISK_TYPE[driveSetting.drive.type]]);
	}

	stopSelectingResource(driveSetting: IDriveSetting) {
		this.selectingResourceForDiskIndex = -1;
		this.selectingResourceTypes = _.filter(this.selectingResourceTypes, type => !AVAILABLE_RESOURCES_BY_DISK_TYPE[driveSetting.drive.type].includes(type));
	}

	selectTemplate(template: string) {
		this.selectedTemplateId = template;
		this.createEnvironmentPayload = {
			nativeConfig: this.selectedTemplate?.nativeConfig?.value || '',
			driveSettings: this.selectedTemplate?.drive.map((drive, index) => ({ drive: drive, driveIndex: index, bootDrive: false })),
			templateId: this.selectedTemplate?.id,
			operatingSystemId: this.selectedTemplate?.operatingSystemId || 'default',
			label: this.selectedTemplate?.description.title,
		};
		this.selectingResourceForDiskIndex = -1;
		this.selectedResourcesPerDrive = this.selectedTemplate?.drive.map(() => []);
	}

	updateObjectsList(evt) {
		if (evt.added) {
			this.selectedResourcesPerDrive[this.selectingResourceForDiskIndex] = [evt.added.element];
			this.selectingResourceTypes = _.filter(this.selectingResourceTypes, type =>
				!AVAILABLE_RESOURCES_BY_DISK_TYPE[this.driveSettings[this.selectingResourceForDiskIndex].drive.type].includes(type));
			this.selectingResourceForDiskIndex = -1;
		}
	}

	getTypeTags(resource: IEaasiResource) {
		return getResourceTypeTags(resource);
	}

	getArchiveTags(resource: IEaasiResource) {
		const archive = resource.archive || resource.archiveId;
		let tagGroup = [];
		if (archive === archiveTypes.PUBLIC) {
			tagGroup.push({
				icon: translatedIcon('map-marker'),
				color: 'green',
				text: 'Saved Locally'
			});
		} else if (archive === archiveTypes.REMOTE) {
			tagGroup.push({
				icon: 'fa-cloud',
				color: 'white',
				text: 'Remote'
			});
		} else if (archive === archiveTypes.DEFAULT) {
			tagGroup.push({
				color: 'yellow',
				text: 'Local'
			});
		}
		return tagGroup;
	}

	clearObject(driveSetting: IDriveSetting) {
		this.selectedResourcesPerDrive = this.selectedResourcesPerDrive.map((resources, index) => index === driveSetting.driveIndex ? [] : resources);
	}

	async init() {
		await this.$store.dispatch('resource/getTemplates');
		if (this.createEnvironmentPayload) {
			this.selectedTemplateId = this.createEnvironmentPayload.templateId;
		}
		this.selectingResourceForDiskIndex = -1;
	}

	async created() {
		await this.init();
	}

}
