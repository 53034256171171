












import Vue from 'vue';
import {Component} from 'vue-property-decorator';

/**
 * A styled container for tips for users
 * @example ../docs/TipsForSuccess.Example.md
 */
@Component({
	name: 'TipsForSuccess'
})
export default class TipsForSuccess extends Vue {}
