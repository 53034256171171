













import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {ILabeledEditableItem} from '@/types/ILabeledItem';

@Component({
	name: 'TextItem',
	components: {},
})
export default class TextItem extends Vue {

	@Prop()
	item: ILabeledEditableItem;


	@Prop({ type: Boolean })
	readonly: boolean;


}
