import { render, staticRenderFns } from "./ImportedResourcesScreen.vue?vue&type=template&id=4c670622&"
import script from "./ImportedResourcesScreen.vue?vue&type=script&lang=ts&"
export * from "./ImportedResourcesScreen.vue?vue&type=script&lang=ts&"
import style0 from "./ImportedResourcesScreen.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports