


























import { Component } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import Vue from 'vue';

@Component({
	name: 'SaveEnvironmentModal',
})
export default class SaveEnvironmentModal extends Vue {

	@Get('emulationProject/createEnvironmentPayload@label')
	initialLabel: string;

	label: string;

	created() {
		this.label = this.initialLabel;
	}

}
