

















import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import EditableTextItem from '@/components/resources/view-details/shared/EditableTextItem.vue';
import TextItem from '@/components/emulation-project/shared/TextItem.vue';
import {ITemplate} from '@/types/Import';

@Component({
	name: 'EmulationProjectHardwareMetadata',
	components: {TextItem, EditableTextItem},
})
export default class EmulationProjectHardwareMetadata extends Vue {

	@Prop()
	template: ITemplate;

	get devicesMetadata() {
		return [
			{
				label: 'Emulator Configuration',
				value: this.template?.nativeConfig?.value || '-',
				property: 'audioDevice',
				readonly: true,
				editType: 'text-input',
				changed: false
			},
		];
	}

	get environmentMetadata() {
		return [
			{
				label: 'Emulator',
				value: this.template.emulator?.bean || '-',
				property: 'id',
				readonly: true,
				editType: 'text-input',
				changed: false
			},
		];
	}

}
