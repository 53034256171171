













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Checkbox from '@/components/global/forms/Checkbox.vue';

@Component({
	name: 'SelectableTextCard',
	components: {
		Checkbox
	}
})
export default class SelectableTextCard extends Vue {

	/* Props
	============================================*/
	@Prop({ type: Boolean, required: true })
	readonly value: boolean;

	@Prop({ type: Boolean, default: false })
	readonly disabled: boolean;

	@Prop({type: Boolean, required: false, default: true})
	readonly isClickable: boolean;

	@Prop({ type: String, required: true })
	readonly label: string;

	/* Computed
	============================================*/
	get selectStyle() : string {
		return this.value ? 'selected' : '';
	}

	/* Methods
	============================================*/
	isChecked(val) {
		return val === this.value;
	}

	toggleSelected() : void {
		this.$emit('input', !this.value);
		this.$emit('change', !this.value);
	}

}

