














































































































































































import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {IEaasiTab} from 'eaasi-nav';
import InfoMessage from './shared/InfoMessage.vue';
import {Get, Sync} from 'vuex-pathify';
import {IEaasiResource, IEnvironment, ResourceType} from '@/types/Resource';
import {
	resourceTypes,
	IResourceTypes,
	translatedIcon,
	archiveTypes,
} from '@/utils/constants';
import {getResourceTypeTags} from '@/helpers/ResourceHelper';
import EnvironmentResourceCard from '@/components/resources/EnvironmentResourceCard.vue';
import SoftwareResourceCard from '@/components/resources/SoftwareResourceCard.vue';
import ContentResourceCard from '@/components/resources/ContentResourceCard.vue';
import {ROUTES} from '@/router/routes.const';
import EmulationProjectEnvironment from '@/models/emulation-project/EmulationProjectEnvironment';
import DraggableCard from '@/components/global/DraggableCard/DraggableCard.vue';
import SelectableRadioCard from '@/components/global/SelectableCard/SelectableRadioCard.vue';
import Draggable from 'vuedraggable';
import _ from 'lodash';

@Component({
	name: 'ResourceSideBar',
	components: {
		DraggableCard,
		EnvironmentResourceCard,
		SoftwareResourceCard,
		ContentResourceCard,
		InfoMessage,
		SelectableRadioCard,
		Draggable
	}
})
export default class ResourceSideBar extends Vue {

	/* Props
	============================================*/

	/* Computed
	============================================*/

	@Sync('emulationProject/environment')
	environment: EmulationProjectEnvironment;

	@Get('emulationProject/projectResources')
	readonly resources: IEaasiResource[];

	@Sync('emulationProject/selectedResources')
	selected: IEaasiResource[];

	@Get('emulationProject/constructedFromBaseEnvironment')
	constructedFromBaseEnvironment: boolean;

	@Sync('emulationProject/selectingResourceTypes')
	selectingResourceTypes: ResourceType[];

	@Get('emulationProject/selectedResourcesPerDrive')
	selectedResourcesPerDrive: IEaasiResource[][];

	get isSelectingEnvironment() {
		return this.selectingResourceTypes.includes(resourceTypes.ENVIRONMENT) && !this.environment;
	}

	get isSelectingSoftware() {
		return this.selectingResourceTypes.includes(resourceTypes.SOFTWARE);
	}

	get isSelectingContent() {
		return this.selectingResourceTypes.includes(resourceTypes.CONTENT);
	}

	get isSelectingImage() {
		return this.selectingResourceTypes.includes(resourceTypes.IMAGE);
	}

	isSelectingObject(type: ResourceType) {
		switch (type) {
			case 'Content':
				return this.isSelectingContent;
			case 'Software':
				return this.isSelectingSoftware;
			case 'Environment':
				return this.isSelectingEnvironment;
			case 'Image':
				return this.isSelectingImage;
			default:
				return false;
		}
	}

	isResourceSelectedForDrive(id: string) {
		return _.flatten(this.selectedResourcesPerDrive).some(resource => resource.id === id);
	}

	get resourceLimit(): number {
		if (this.constructedFromBaseEnvironment) {
			return this.environment ? this.environment.drives.length : this.defaultDriveLimit;
		} else return 1;
	}

	@Get('emulationProject/projectEnvironments')
	readonly environments: IEnvironment[];

	@Get('emulationProject/projectObjects')
	objects: IEaasiResource[];

	@Get('emulationProject/projectImages')
	images: IEaasiResource[];

	get hasObjectSlots(): boolean {
		return this.resourceLimit > this.selected.length;
	}

	/* Data
	============================================*/
	tabs: IEaasiTab[] = [
		{
			label: 'Project Resources'
		},
	];

	emptyImage: IEaasiResource = {
		title: 'Empty Disk',
		resourceType: 'Image',
		isPublic: false,
		isEmpty: true,
	};

	defaultDriveLimit: number = 3;
	activeTab: IEaasiTab = this.tabs[0];
	resourceTypes: IResourceTypes = resourceTypes;

	/* Methods
	============================================*/

	getTypeTags(resource: IEaasiResource) {
		return getResourceTypeTags(resource);
	}

	getArchiveTags(resource: IEaasiResource) {
		let tagGroup = [];
		if (resource.archive === archiveTypes.PUBLIC) {
			tagGroup.push({
				icon: translatedIcon('map-marker'),
				color: 'green',
				text: 'Saved Locally'
			});
		} else if (resource.archive === archiveTypes.REMOTE) {
			tagGroup.push({
				icon: 'fa-cloud',
				color: 'white',
				text: 'Remote'
			});
		} else if (resource.archive === archiveTypes.DEFAULT) {
			tagGroup.push({
				color: 'yellow',
				text: 'Local'
			});
		}
		return tagGroup;
	}

	isSelected(resource: IEaasiResource) {
		return this.selected.some(x => x.id === resource.id);
	}

	removeResource(resource: IEaasiResource) {
		this.$store.dispatch('emulationProject/removeResource', resource);
	}

	removeResourcesOfType(resourceTypes: ResourceType[]) {
		this.$store.dispatch('emulationProject/removeResourcesOfType', resourceTypes);
	}

	selectResource(resource: IEaasiResource, selected: boolean) {
		let resourcesToSelect;

		if (!selected || this.isSelected(resource)) {
			resourcesToSelect = this.selected.filter(x => x.id !== resource.id);
		} else {
			resourcesToSelect = [resource];
		}
		this.selected = resourcesToSelect.slice(0, this.resourceLimit);
	}

	setEnvironment(environment: IEnvironment, checked: boolean) {
		if (checked) {
			this.environment = new EmulationProjectEnvironment(environment);
			this.$router.push(ROUTES.EMULATION_PROJECT.OPTIONS);
		} else {
			this.environment = null;
			this.$router.push(ROUTES.EMULATION_PROJECT.OPTIONS);
		}
	}

	isDisabled(resource: IEaasiResource): boolean {
		return !this.isSelected(resource) && !this.hasObjectSlots && this.selected.length > 0;
	}

	/* Lifecycle Hooks
	============================================*/

}
