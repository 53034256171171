




































































































import {ISaveEnvOptions} from '@/types/SaveEnvironment';
import eventBus from '@/utils/event-bus';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {Get} from 'vuex-pathify';
import ChangeMediaModal from './ChangeMediaModal.vue';
import SaveEnvironmentModal from './SaveEnvironmentModal.vue';
import PrintJobsModal from './PrintJobsModal.vue';
import { IEnvironment } from '@/types/Resource';
import {ICreateEnvironmentPayload} from '@/types/Import';

@Component({
	name: 'AccessInterfaceHeader',
	components: {
		ChangeMediaModal,
		PrintJobsModal,
		SaveEnvironmentModal
	}
})
export default class AccessInterfaceHeader extends Vue {

	/* Computed
	============================================*/
	@Get('emulatorIsRunning')
	readonly emulatorIsRunning: boolean;

	@Get('resource/activeEnvironment')
	readonly activeEnvironment: IEnvironment;

	@Get('resource/activeEphemeralEnvironment')
	createEnvironmentPayload: ICreateEnvironmentPayload;

	/* Data
	============================================*/
	mediaItems: [] = [];
	showMediaOptions: boolean = false;
	showSaveEnvironment: boolean = false;
	showPrintJobsModal: boolean = false;
	printJobLabels: string[] = [];
	enableChangeMedia: boolean = false;
	driveId: number;

	/* Methods
	============================================*/
	emitTakeScreenshot() {
		eventBus.$emit('emulator:takeScreenshot');
	}

	emitSendEscape() {
		eventBus.$emit('emulator:send:escape');
	}

	emitSendCtrlAltDelete() {
		eventBus.$emit('emulator:send:ctrlAltDelete');
	}

	downloadPrintJob(label: string) {
		eventBus.$emit('emulator:print:download-print-job', label);
	}

	saveEnvironment(saveEnvOptions: ISaveEnvOptions) {
		eventBus.$emit('emulator:saveSnapshot', saveEnvOptions);
	}

	goToDashboard() {
		this.$router.push({ 'name': 'Dashboard' });
	}

	changeMedia(mediaId) {
		const { softwareId, objectId, archiveId } = this.$route.query;
		const label = mediaId === 'empty' ? '' : mediaId;
		const changeMediaRequest = {
			objectId: softwareId ? softwareId : objectId,
			label,
			driveId: this.driveId,
			archiveId: archiveId
		};
		eventBus.$emit('emulator:change-media', changeMediaRequest);
		this.showMediaOptions = false;
	}

	initEmulatorListeners() {
		eventBus.$on('emulator:print:add-print-job', filename => this.printJobLabels.push(filename));
		eventBus.$on('emulator:set-media', async removableMediaList => {
			if (!removableMediaList || removableMediaList.length === 0) {
				return;
			}
			const mediaItems: [] = [];
			for (let mediaItem of removableMediaList) {
				const result = await this.$store.dispatch('software/getSoftwareMetadata', {
					archiveId: mediaItem.archive,
					objectId: mediaItem.id,
				});
				const files = result.mediaItems.file.map(file => ({
					...file,
					objectId: mediaItem.id
				}));
				mediaItems.push(...(files as []));
			}
			this.mediaItems = mediaItems;
			this.driveId = removableMediaList[0].driveIndex;
			this.enableChangeMedia = true;
		});
	}

	initBrowserEvents() {
		// Empty!
	}

	removeBrowserEvents() {
		window.removeEventListener('beforeunload', () => {});
	}

	async mounted() {
		/*const { softwareId, objectId, archiveId} = this.$route.query;
		if ((softwareId || objectId) && archiveId) {
			const result = await this.$store.dispatch('software/getSoftwareMetadata', {
				archiveId,
				objectId: softwareId ? softwareId : objectId,
			});
			this.mediaItems = result.mediaItems.file;
		}*/
		this.initEmulatorListeners();
		this.initBrowserEvents();
	}

	beforeDestroy() {
		this.removeBrowserEvents();
		eventBus.$off('emulator:print:add-print-job');
	}
}
