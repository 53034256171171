











import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {EmulationProjectMode} from '@/types/EmulationProject';
import {Get, Sync} from 'vuex-pathify';
import {IEaasiResource, IEnvironment} from '@/types/Resource';
import Draggable from 'vuedraggable';
import EmulationProjectEnvironment from '@/models/emulation-project/EmulationProjectEnvironment';
import EmulationProjectEnvironmentMetadata
	from '@/components/emulation-project/metadata/EmulationProjectEnvironmentMetadata.vue';
import EmulationProjectBasicModeScreen from '@/components/emulation-project/EmulationProjectBasicModeScreen.vue';
import EmulationProjectAdvancedModeScreen from '@/components/emulation-project/EmulationProjectAdvancedModeScreen.vue';
import {ICreateEnvironmentPayload} from '@/types/Import';

@Component({
	name: 'EmulationProjectModeScreen',
	components: {
		EmulationProjectAdvancedModeScreen,
		EmulationProjectBasicModeScreen,
		EmulationProjectEnvironmentMetadata,
		Draggable
	}
})
export default class EmulationProjectModeScreen extends Vue {

	@Prop({ type: String })
	mode: EmulationProjectMode;

	@Sync('emulationProject/selectingResourceTypes')
	selectingResourceTypes: string[];

	@Sync('emulationProject/environment')
	environment: EmulationProjectEnvironment;

	@Sync('emulationProject/selectedResources')
	selected: IEaasiResource[];

	@Sync('emulationProject/selectedResourcesPerDrive')
	selectedResourcesPerDrive: IEaasiResource[][];

	@Sync('emulationProject/selectedTemplateId')
	selectedTemplateId: string;

	@Sync('emulationProject/createEnvironmentPayload')
	createEnvironmentPayload: ICreateEnvironmentPayload;

	@Get('emulationProject/projectEnvironments')
	readonly environments: IEnvironment[];

	get isBasicMode() {
		return this.mode === EmulationProjectMode.Basic;
	}

	get isAdvancedMode() {
		return this.mode === EmulationProjectMode.Advanced;
	}

	setSelectingResourceTypes(types: ResponseType[]) {
		this.selectingResourceTypes = types;
	}

	setSelectedResources(resources: IEaasiResource[]) {
		this.selected = resources;
	}

	setEnvironment(environment: EmulationProjectEnvironment) {
		this.environment = environment;
	}

	backToStart() {
		this.environment = null;
		this.selected = [];
		this.$emit('reset');
		this.selectingResourceTypes = [];
		this.createEnvironmentPayload = null;
		this.selectedTemplateId = null;
		this.selectedResourcesPerDrive = [];
	}

}
