











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A tooltip with icon
 * @example ../docs/Tooltip.Example.md
 */
@Component({
	name: 'Tooltip'
})
export default class Tooltip extends Vue {

	$refs!: {
		content: HTMLDivElement
		icon: HTMLSpanElement
	}
	/* Props
    ============================================*/

	/**
	 * The tooltip icon
	 */
	@Prop({type: String, required: false, default: 'question'})
	readonly icon: string;

	/**
	 * The tooltip content
	 */
	@Prop({type: String})
	readonly text: string;

	open: boolean = false;

	readonly offset: number = 10;

	openTooltip(): void {
		this.recalculateTooltipPosition();
		this.open = true;
	}

	closeTooltip(): void {
		this.open = false;
	}

	recalculateTooltipPosition(): void {
		const width = this.$refs.content.getBoundingClientRect().width;
		const height = this.$refs.content.getBoundingClientRect().height;
		let x = this.$refs.icon.getBoundingClientRect().x + this.$refs.icon.getBoundingClientRect().width / 2;
		if (x + width / 2 > window.innerWidth) {
			x = window.innerWidth - width / 2;
		}
		if (x < 0) {
			x = 0;
		}
		let y = this.$refs.icon.getBoundingClientRect().y + this.$refs.icon.getBoundingClientRect().height;
		if (y + height > window.innerHeight) {
			y = window.innerHeight - height - this.offset;
		}
		if (y < -this.offset) {
			y = -this.offset;
		}
		this.$refs.content.style.left = window.scrollX + x + 'px';
		this.$refs.content.style.top = window.scrollY + y + 'px';
	}
}
