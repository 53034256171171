














































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import AdminScreen from '../AdminScreen.vue';
import config from '@/config';
import EaasiIcon from '@/components/global/icons/EaasiIcon.vue';
import eventBus from '@/utils/event-bus';
import { generateId } from '@/utils/functions';
import { INotification } from '@/types/Notification';

@Component({
    name: 'TroubleshootingSection',
	components: {
		EaasiIcon
	}
})
export default class TroubleshootingSection extends AdminScreen {

    /* Props
    ============================================*/

    /* Computed
    ============================================*/
    get emilErrorDownloadUrl(): string {
        return config.EMIL_SERVICE_ENDPOINT + '/error-report';
	}

	get webApiErrorDownloadUrl(): string {
		return config.SERVICE_URL + '/error-report/download-all';
	}
	get disabled(): boolean {
		return this.checkedArchives.length === 0;
	}

    /* Data
    ============================================*/

	checkedArchives: string[] = [];

    /* Methods
    ============================================*/

	isChecked(type: string) {
		return this.checkedArchives.includes(type);
	}

	onToggle(type: string) {
		if (this.checkedArchives.includes(type)) {
			this.checkedArchives = this.checkedArchives.filter(f => f !== type);
		} else {
			this.checkedArchives.push(type);
		}
	}

	async refreshArchives(checkedArchives: string[]) {
		 for (const archiveType of checkedArchives) {
			switch (archiveType) {
				case 'image_archive':
					const resultSyncImages = await this.$store.dispatch('resource/syncImagesUrl');

					if (!resultSyncImages) continue;
					if (resultSyncImages.status === '0') {
						this.$emit('full-refresh');
					} else {
						let notification: INotification = {
							label: 'Failed to refresh image archive',
							time: 5000,
							type: 'danger',
							id: generateId()
						};
						eventBus.$emit('notification:show', notification);
					}
					continue;
				case 'object_archive':
					const resultSyncObjects = await this.$store.dispatch('resource/syncObjectsUrl');

					if (!resultSyncObjects) continue;
					if (resultSyncObjects.status === '0') {
						this.$emit('full-refresh');
					} else {
						let notification: INotification = {
							label: 'Failed to refresh object archive',
							time: 5000,
							type: 'danger',
							id: generateId()
						};
						eventBus.$emit('notification:show', notification);
					}
					continue;
				case 'software_archive':
					const resultSyncObject = await this.$store.dispatch('resource/syncObjectsUrl');
					const resultSyncSoftware = await this.$store.dispatch('resource/syncSoftwareUrl');

					if (!resultSyncObject && !resultSyncSoftware) continue;
					if (resultSyncObject.status === '0' && resultSyncSoftware.status === '0') {
						this.$emit('full-refresh');
					} else {
						let notification: INotification = {
							label: 'Failed to refresh software archive',
							time: 5000,
							type: 'danger',
							id: generateId()
						};
						eventBus.$emit('notification:show', notification);
					}
					continue;
				default:
					continue;
		}
		 }
		this.checkedArchives = [];
	}

    /* Lifecycle Hooks
    ============================================*/

}
