







































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import EmulatorImportRequest from '@/models/admin/EmulatorImportRequest';
import { ITaskState } from '@/types/Task';

@Component({
	name: 'EmulatorModal'
})
export default class EmulatorImportModal extends Vue {

	/* Data
	============================================*/

	importRequest: EmulatorImportRequest = new EmulatorImportRequest();

	/* Methods
	============================================*/

	cancel() {
		this.$emit('close');
	}

	/**
	 * Sends initial import request and registers new task
	 */
	async doImport() {
		let task = await this.$store.dispatch('admin/importEmulator', this.importRequest) as ITaskState;
		if(!task) return;
		await this.$store.dispatch('task/addTaskToQueue', task);
		this.$emit('close');
	}
}

