






















import PermissionResolver from '@/services/Permissions/PermissionResolver';
import {IEaasiUser} from 'eaasi-admin';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IMenuItem } from 'eaasi-nav';
import AdminMenuItem from './AdminMenuItem.vue';
import User from '@/models/admin/User';
import { ROUTES } from '@/router/routes.const';
import config from '@/config';
import { EDITION_TYPES, userRoles } from '@/utils/constants';

@Component({
	name: 'AdminMenu',
	components: {
		AdminMenuItem
	}
})
export default class AdminMenu extends Vue {

	@Get('loggedInUser')
	user: IEaasiUser;

	@Get('permissions')
	permit: PermissionResolver;

	/* Data
	============================================*/

	get isViewable(): boolean {
		if (!this.user) return false;
		return this.permit.allowsManageNodeUsers();
	};

	get isUserMenuViewable(): boolean {
		return this.permit.allowsUserManageNodeItems();
	}

	get roleType(): string {
		switch(this.permit.userRoleId) {
			case userRoles.ADMIN: {
				return 'ADMIN';
			}
			case userRoles.MANAGER: {
				return 'MANAGER';
			}
			case userRoles.CONTRIBUTOR: {
				return 'CONTRIBUTOR';
			}
		}
	}

	get menuItems(): IMenuItem[] {
		let menuItems = [];
		if (this.permit.allowsManageNodeItems() && config.EDITION_TYPE == EDITION_TYPES.STANDALONE) {
			menuItems.push(
				{
					icon: 'server',
					label: 'Emulators',
					route: ROUTES.MANAGE_NODE.EMULATORS
				},
				{
					icon: 'sync-alt',
					label: 'Endpoints / Metadata Sync',
					route: ROUTES.MANAGE_NODE.METADATA_SYNC
				}
			);
		}
		menuItems.push(
			{
				icon: 'tasks',
				label: 'Running Tasks',
				route: ROUTES.MANAGE_NODE.RUNNING_TASKS
			}
		);
		menuItems.push(
			{
				label: 'Troubleshooting',
				route: ROUTES.MANAGE_NODE.TROUBLESHOOTING,
				icon: 'wrench'
			}
		);

		return menuItems;
	}

	get userMenuItems(): IMenuItem[] {
		let userMenuItems = [];
		if (this.permit.allowsUserManageNodeItems()) {
			userMenuItems.push(
				{
					icon: 'user',
					label: 'Create New User',
					route: '',
					onClick: () => this.addUser()
				},
				{
					icon: 'users',
					label: 'Manage Users',
					route: ROUTES.MANAGE_NODE.USERS
				}
			);
		}
		return userMenuItems;
	}

	/* Computed
	============================================*/

	@Get('appVersion')
	readonly appVersion: string;

	@Get('buildVersion')
	readonly frontEndBuild: string;

	backEndBuild: string = '';

	async getBEBuildVersion() {
		this.backEndBuild = await this.$store.dispatch('admin/getBEBuildVersion');
	}

	/* Methods
	============================================*/

	addUser() {
		this.$router.push(ROUTES.MANAGE_NODE.USERS);
		this.$store.commit('admin/SET_ACTIVE_USER', new User());
	}

	mounted() {
		this.getBEBuildVersion();
	}
}

