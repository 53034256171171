






















import Vue from 'vue';
import { Get } from 'vuex-pathify';
import { Component, Prop } from 'vue-property-decorator';
import { IEaasiResourceSummary, IEaasiResource, IImageListItem } from '@/types/Resource.d.ts';
import { ITag } from '@/types/Tag';
import { IBookmark } from '@/types/Bookmark';
import { resourceLabels, resourceTypes, translatedIcon } from '@/utils/constants';

@Component({
	name: 'ImageResourceCard',
})
export default class ImageResourceCard extends Vue {

	/* Props
	============================================*/
	@Prop({type: Object as () => IImageListItem, required: true})
	readonly image: IImageListItem;

	@Prop({type: Boolean, required: false, default: false})
	readonly disableSelect: boolean;

	@Prop({type: Boolean, required: false, default: false})
	readonly isClickable: boolean;

	@Prop({ type: Boolean, default: true })
	readonly bookmark: boolean;

	/* Data
	============================================*/
	imageTypeTags: ITag[] =  [
		{
			text: resourceLabels.IMAGE,
			icon:  translatedIcon('disk'),
			color: 'white'
		}
	];

	/* Computed
	============================================*/
	@Get('resource/selectedResources')
	selectedResources: IEaasiResource[];

	@Get('bookmark/bookmarks')
	bookmarks: IBookmark[];

	get imageTagGroup(): ITag[] {
		// Currently, images are not public
		if (this.image.isPublic) {
            return [{
                text: 'Public',
				icon:  translatedIcon('public-network'),
                color: 'green'
            }];
		} else {
			return [{
				text: 'Shared to Node',
				icon:  translatedIcon('unlock'),
				color: 'yellow'
			}];
        }
	}

	get summary(): IEaasiResourceSummary {
		return {
			id: this.image.id,
			title: this.image.title,
			resourceType: resourceTypes.IMAGE,
			content: { },
			subContent: { },
			isPublic: false
		};
	}

	get isBookmarkSelected(): Boolean {
		return this.bookmarks?.some(b => b.resourceId === this.image.id);
	}

	get isSelected(): Boolean {
		return this.selectedResources.some(r => r.id === this.image.id);
	}

	/* Methods
	============================================*/
	goToDetailsPage() {
        throw 'not implemented';
		// this.$router.push({
		// 	path: ROUTES.RESOURCES.CONTENT,
		// 	query: {
		// 		resourceId: this.content.id.toString(),
		// 		archiveId: this.content.archiveId.toString()
		// 	}
		// });
	}

}

